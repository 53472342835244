/* ==============================
    Basic/generic global styles
 ============================== */

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: calc(100% + 1px);
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.3s $easeOutQuad;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

[hidden] {
    display: none !important;
}

.g-wrap {
    // Battling a weird flexbox bug where... this helps :|
    width: 100%;
    &--wide {
        max-width: 2560px !important;
    }
}

th, td {
    vertical-align: top;
    text-align: left;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input:required {
    box-shadow: none;
}

html.no-outline * {
    outline: none;
}

::-moz-selection {
    background: $colorText;
    color: $colorBackground;
}

::selection {
    background: rgba($colorText, 0.99);
    color: $colorBackground;
}

button, a {
    touch-action: manipulation;
}

[data-rellax] {
    will-change: transform;
}

html.js:not(.js-error):not(.is-live-preview) [data-reveal]:not(.js-reveal) {
    opacity: 0;
}

a, button {
    touch-action: manipulation;
}
