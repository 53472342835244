.video-embed {

    &__btn {
        width: 26%;
        max-width: 120px;
    }

    iframe {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
    }
}
