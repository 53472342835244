/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

@layer base {

    /**
        Macula
     */
    @font-face {
        font-family: 'Macula-Background';
        font-weight: normal;
        font-style: normal;
        src: url('../fonts/macula/woff/Macula-Background.woff2') format('woff2'),
             url('../fonts/macula/woff/Macula-Background.woff') format('woff');
        font-display: swap;
    }

    @font-face {
        font-family: 'Macula-Line';
        font-weight: normal;
        font-style: normal;
        src: url('../fonts/macula/woff/Macula-Line.woff2') format('woff2'),
             url('../fonts/macula/woff/Macula-Line.woff') format('woff');
        font-display: swap;
    }

    @font-face {
        font-family: 'Macula-Shaded';
        font-weight: normal;
        font-style: normal;
        src: url('../fonts/macula/woff/Macula-Shaded.woff2') format('woff2'),
             url('../fonts/macula/woff/Macula-Shaded.woff') format('woff');
        font-display: swap;
    }

    @font-face {
        font-family: 'Macula-Shading';
        font-weight: normal;
        font-style: normal;
        src: url('../fonts/macula/woff/Macula-Shading.woff2') format('woff2'),
        url('../fonts/macula/woff/Macula-Shading.woff') format('woff');
        font-display: swap;
    }

    @font-face {
        font-family: 'Macula-Solid';
        font-weight: normal;
        font-style: normal;
        src: url('../fonts/macula/woff/Macula-Solid.woff2') format('woff2'),
             url('../fonts/macula/woff/Macula-Solid.woff') format('woff');
        font-display: swap;
    }

    /**
        Myriad (TypeKit)
     */
    // Myriad Pro Regular
    @font-face {
        font-family: myriad-pro, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    // Myriad Pro Regular Italic
    @font-face {
        font-family: myriad-pro, sans-serif;
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }

    // Myriad Pro Semi-Bold
    @font-face {
        font-family: myriad-pro, sans-serif;
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    // Myriad Pro Semi-Bold Italic
    @font-face {
        font-family: myriad-pro, sans-serif;
        font-weight: 600;
        font-style: italic;
        font-display: swap;
    }
}
