/*
==========================
Fonts
** Remember to configure font families for Tailwind in src/tailwind.config.js
==========================
 */
$fontSizeBase: 16px;
$lineHeightBase: (22/16);
$fontDefault: myriad-pro, sans-serif;

/*
==========================
Colors
** Remember to configure colors for Tailwind in src/tailwind.config.js
==========================
 */
$colorBlack: black;
$colorWhite: white;

$colorDarkGui: #131B43;
$colorDarkBlue: #1E285A;
$colorBlue: #2D2D87;
$colorPurple: #585E92;
$colorShadowBlue: #99D2ED;
$colorSkyBlue: #ACCBEE;
$colorCloudBlue: #E7F0FD;
$colorYellow: #FFF500;
$colorOrange: #FFC229;
$colorRed: #FF4A54;

$colorGreenGradientFrom: #75D080;
$colorGreenGradientTo: #95EEA0;

$colorSignalGradientFrom: #F6D365;
$colorSignalGradientTo: #FDA085;

$colorText: $colorDarkBlue;
$colorBackground: $colorCloudBlue;

/*
==========================
Breakpoints
** Remember to configure breakpoints for Tailwind in tailwind.config.js!**
==========================
 */

$small: 375px;
$smallPlus: 540px;
$medium: 768px;
$mediumPlus: 980px;
$large: 1100px;
$largePlus: 1420px;
$xlarge: 1800px;

$breakpoints: (
    s: $small,
    sp: $smallPlus,
    m: $medium,
    mp: $mediumPlus,
    l: $large,
    lp: $largePlus,
    xl: $xlarge,
);

/*
==========================
Grid settings
==========================
 */

$gutterMap: (
    min: 24px,
    s: 24px,
    sp: 24px,
    m: 40px,
    mp: 40px,
    l: 40px,
    lp: 40px,
    xl: 40px
);

$outerMarginsMap: (
    min: 16px,
    s: 16px,
    sp: 16px,
    m: 30px,
    mp: 30px,
    l: 60px,
    lp: 80px,
    xl: 100px
);

$maxContentWidth: 1680px;
$maxContentViewportWidth: $maxContentWidth + (outerMargin(xl) * 2);

// If different max widths are needed, comment this in and comment the variable above out.
//
// $maxContentWidth: (
//     min: 1280px,
//     s: 1280px,
//     sp: 1280px,
//     m: 1280px,
//     mp: 1280px,
//     l: 1280px,
//     lp: 1440px,
//     xl: 1600px
// );

$grid-breakpointMap: $breakpoints;
$grid-breakpoints: (s, sp, m, mp, l, lp, xl);
$grid-columns: 12;
$grid-outer-margins: $outerMarginsMap;
$grid-gutters: $gutterMap;
$grid-flexbox: true;
$grid-pull-columns: 6;
$grid-push-columns: 6;
$grid-use-long-classnames: false;
$grid-use-em: false;
$grid-max-width: $maxContentWidth; //Should include support for multiple max-widths at different breakpoings. See NAF Digital
//$grid-max-width-map: $maxContentWidth; // Optional. Use if different max widths are needed at different breakpoints
