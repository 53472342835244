.quiz-progress {
    &__item {

        width: 84px;

        &-icon {
            width: 32px;
            height: 32px;
        }

        &-bar {
            width: 4px;
            height: 32px;
            border-radius: 24px;
        }

        @include from(m) {
            width: 32px;
            &-bar {
                width: 32px;
                height: 4px;
            }
        }
    }
}
