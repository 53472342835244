.page {
    &__hero {
        padding-top: #{'min(40vw, 200px)'};
        padding-bottom: #{'min(30vw, 150px)'};
        @include from(m) {
            padding-top: #{'min(33vw, 400px)'};
            padding-bottom: #{'min(25vw, 250px)'};
        }
    }
}
