@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0px rgba($colorSkyBlue, 1);
    }
    75% {
        box-shadow: 0 0 0 24px rgba($colorSkyBlue, 0);
    }
    100% {
        box-shadow: 0 0 0 24px rgba($colorSkyBlue, 0);
    }
}

.hotspot {

    &__inner {
        width: 90px;
        height: 90px;

        @include from(m) {
            width: 110px;
            height: 110px;
        }
    }

    svg {
        width: 24px;
        height: 24px;
    }

    &__ring, &__pulse {
        width: 48px;
        height: 48px;
        left: 50%;
        top: 50%;
        margin: -24px 0 0 -24px;

        @include from(m) {
            width: 64px;
            height: 64px;
            margin: -32px 0 0 -32px;
        }
    }

    &__pulse {
        animation: pulse 2s $easeInOutSine infinite 1s;
        &:nth-of-type(2) {
            animation: pulse 2s $easeInOutSine infinite 1.25s;
        }
    }

    &__label {
        &--left {
            right: calc(100% - 14px);
        }
        &--right {
            left: calc(100% - 14px);
        }
    }

    @media (hover: hover) {
        &__ring, &__label {
            transition: transform 0.3s;
        }
        &:hover &__ring,
        &:focus &__ring {
            transform: scale(1.2);
        }
        &:hover &__label {
            &--left {
                transform: translate(-8px, -50%);
            }
            &--right {
                transform: translate(8px, -50%);
            }
        }
    }

    &:focus &__label {
        &--left {
            transform: translate(-10px, -50%);
        }
        &--right {
            transform: translate(10px, -50%);
        }
    }
}
