.quiz-draw {
    &__canvas {
        width: 70vmin;
        max-width: 520px;

        @include from(m) {
            width: 45vmin;
        }

        &-pencil {
            width: 40px;
            height: 41px;
        }

    }

    &__ui {
        &-btn {
            width: 48px;
            height: 48px;
            svg {
                width: 32px;
                height: 32px;
            }
        }
        &-color {
            width: 22px;
            height: 22px;
        }
        &-circle {
            width: 40px;
            height: 40px;

            .is-active & {
                opacity: 1;
            }
        }
    }
}
