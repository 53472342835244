$largeBp: $medium (orientation landscape), $large;

/* Buzz Out */
@keyframes buzz-out {
    10% {
        transform: translateX(3px) rotate(2deg);
    }

    20% {
        transform: translateX(-3px) rotate(-2deg);
    }

    30% {
        transform: translateX(3px) rotate(2deg);
    }

    40% {
        transform: translateX(-3px) rotate(-2deg);
    }

    50% {
        transform: translateX(2px) rotate(1deg);
    }

    60% {
        transform: translateX(-2px) rotate(-1deg);
    }

    70% {
        transform: translateX(2px) rotate(1deg);
    }

    80% {
        transform: translateX(-2px) rotate(-1deg);
    }

    90% {
        transform: translateX(1px) rotate(0);
    }

    100% {
        transform: translateX(-1px) rotate(0);
    }
}

.quiz-cards {

    @include from(m) {
        max-width: 488px;
    }

    @include breakpoint($largeBp) {
        max-width: none;
        flex-wrap: nowrap;
    }

    &__card {

        width: 220px;

        &-wrap {
            transition: transform 0.3s $easeOutBack;
        }

        &-inner {
            transition: transform 0.1s linear;
        }

        &:active &-inner {
            transform: scale(0.95);
        }

        &-bg {
            transition: background-color 0.3s;
        }

        &-bg-success {
            background: linear-gradient(90deg, $colorGreenGradientFrom 20.31%, $colorGreenGradientTo 100%);
            transition: opacity 0.3s;
        }

        @media (hover: hover) {
            &:hover {
                z-index: 10 !important;
            }
            &:hover &-bg {
                background-color: $colorSkyBlue;
            }
            &:not(.is-correct):hover &-wrap {
                transform: scale(1.15) rotate(5deg);
            }
        }

        &.is-correct &-frame svg {
            path {
                transition: fill 0.3s;
            }
            path[fill="#ACCBEE"] {
                fill: $colorGreenGradientFrom;
            }
        }

        &.is-correct &-bg-success {
            opacity: 1;
        }

        &.is-wrong &-inner {
            animation-name: buzz-out;
            animation-duration: .75s;
            animation-timing-function: linear;
            animation-iteration-count: 1;
        }

        &:first-child {
            transform: rotate(4deg);
        }

        &:nth-child(3) {
            transform: rotate(-4deg);
        }

        @include from(sp) {
            &:first-child {
                transform: rotate(4deg);
            }

            &:nth-child(3) {
                transform: none;
            }

            &:nth-child(4) {
                transform: rotate(-4deg);
            }
        }

        @include breakpoint($largeBp) {
            &:first-child {
                transform: rotate(16deg);
                z-index: 2;
            }

            &:nth-child(2) {
                transform: rotate(-16deg);
                z-index: 2;
            }

            &:nth-child(3) {
                transform: rotate(8deg);
                z-index: 2;
            }

            &:nth-child(4) {
                transform: rotate(-16deg);
                z-index: 1;
            }
        }

        &-label {
            top: percentage(190 / 272);
            &-text {
                transform: rotate(-8deg);
            }
        }

        &-image {
            width: percentage(158 / 212);
            top: percentage(24 / 272);
        }
    }
}
