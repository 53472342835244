@keyframes rotate {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-10%);
    }
}

.marquee {
    &__wrap {
        transform: rotate(-8deg);
    }

    &__items {
        will-change: transform;
        animation: rotate 10s infinite linear;
        @supports (--custom:property) {
            animation-duration: var(--duration, 10s);
        }
    }
}
