.mute-toggle {
    $this: &;
    &__icon {
        .is-muted {
            display: none;
        }
    }
    &__icon-muted {
        #{$this}:not(.is-muted) & {
            display: none;
        }
    }
}
