.modal-circle {
    &__shape {
        svg {
            @include breakpoint((orientation portrait)) {
                width: auto;
                height: 105vh;
            }
            @include breakpoint((orientation landscape)) {
                width: auto;
                height: 115vh;
                min-height: 60vw;
            }
        }
    }
    &__content {

        width: 100%;
        height: calc(100% - 40px);
        max-width: 550px;

        @include from(m) {
            width: 50%;
            max-width: 680px;
        }
    }
}
