.strom-panel {

    &__sign {

        padding-bottom: #{'min(30vw, 150px)'};

        @include from(m) {
            padding-bottom: #{'min(25vw, 250px)'};
        }

        &-gfx {
            width: percentage(233 / 320);
            margin-left: -(percentage(35 / 320));
            max-width: 540px;

            @include from(m) {
                width: percentage(532 / 834);
                margin-left: -(percentage(62 / 834));
            }

            @include from(l) {
                width: percentage(570 / 1440);
                //margin-left: percentage(137 / 1440);
                margin-left: 50%;
                transform: translate(-110%, 0);
            }

            &-icon {
                width: percentage(208 / 570);
                left: percentage(230 / 570);
                top: percentage(125 / 743);
            }
        }

        &-hotspot {
            left: percentage(191 / 320);
            top: percentage(82 / 289);

            @include from(m) {
                left: percentage(494 / 834);
                top: percentage(165 / 661);
            }

            @include from(l) {
                left: 50%;
                top: percentage(224 / 734);
                transform: translate(-50%, 0);
            }
        }

        &-text {
            bottom: -5%;

            @include from(m) {
                bottom: -10%;
            }
        }
    }

    &__notouch {
        &-anim {
            max-width: 90vw;
            margin-left: auto;
            margin-right: auto;
            @include from(m) {
                max-width: 60vw;
            }
            @include from(l) {
                width: 150%;
                margin-left: -25%;
                margin-right: 0;
            }
        }
    }

    &__parkedtrains {
        &-anim {
            &-gfx {
                width: 2000px;
                margin-right: -200px;
                @include from(mp) {
                    width: 3000px;
                    margin-right: -300px;
                }
                @include from(lp) {
                    width: 110%;
                    min-width: 3000px;
                    margin-right: 0;
                }
            }
            &-bottom {
                top: 100% - percentage(16 / 440);
            }
        }
    }
}
