.videos {
    &__wrap {
        //width: calc(100% + 40px);
        //margin-left: -20px;
    }
    &__item {
        max-width: 630px;
        //padding: 0 20px;
    }
}
