.videos-panel {
    &__panel {
        max-width: 85%;
    }
    &__content {
        > *, .u-wrap, .g-wrap {
            padding: 0;
        }
    }
}
