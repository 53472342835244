.safeside-panel {
    &__hotspot {
        width: 0;
        height: 0;
        &--1 {
            left: percentage(80 / 320);
            top: percentage(3 / 378);
            @include from(m) {
                left: percentage(250 / 770);
                top: percentage(54 / 426);
            }
        }
        &--2 {
            left: percentage(270 / 320);
            top: percentage(214 / 378);
            @include from(m) {
                left: percentage(580 / 770);
                top: percentage(260 / 426);
            }
        }
        &--3 {
            left: percentage(80 / 320);
            top: percentage(324 / 378);
            @include from(m) {
                left: percentage(200 / 770);
                top: percentage(350 / 426);
            }
        }
    }
}
