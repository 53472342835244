.page-footer {

    &__logo {
        width: 120px;
        height: 19px;

        @include from(m) {
            width: 155px;
            height: 25px;
        }
    }

}
