$largeBp: $medium (orientation landscape), $large;

/* Buzz Out */
@keyframes buzz-out {
    10% {
        transform: translateX(3px) rotate(2deg);
    }

    20% {
        transform: translateX(-3px) rotate(-2deg);
    }

    30% {
        transform: translateX(3px) rotate(2deg);
    }

    40% {
        transform: translateX(-3px) rotate(-2deg);
    }

    50% {
        transform: translateX(2px) rotate(1deg);
    }

    60% {
        transform: translateX(-2px) rotate(-1deg);
    }

    70% {
        transform: translateX(2px) rotate(1deg);
    }

    80% {
        transform: translateX(-2px) rotate(-1deg);
    }

    90% {
        transform: translateX(1px) rotate(0);
    }

    100% {
        transform: translateX(-1px) rotate(0);
    }
}

.quiz-choices {

    $this: &;

    &__choice {

        &-bgwrap {
            transition: transform 0.3s;
        }

        &-bg {
            transition: background-color 0.3s;
        }

        &-bg-success {
            transition: opacity 0.3s;
            background: linear-gradient(90deg, $colorGreenGradientFrom 20.31%, $colorGreenGradientTo 100%);
        }

        &.is-correct &-bg-success {
            opacity: 1;
        }

        &-inner {
            transition: transform 0.1s linear;
        }

        &:active &-inner {
            transform: scale(0.85);
        }

        @media (hover: hover) {
            &:hover {
                z-index: 10;
            }
            &:hover &-bg {
                background-color: $colorSkyBlue;
            }
            &:not(.is-wrong):not(.is-correct):hover &-bgwrap {
                transform: scale(1.075);
            }
        }

        &.is-wrong &-inner {
            animation-name: buzz-out;
            animation-duration: .75s;
            animation-timing-function: linear;
            animation-iteration-count: 1;
        }

    }

    &--circles {

        #{$this}__choice {

            width: 120px;
            height: 120px;

            @include from(m) {
                width: 200px;
                height: 200px;
            }
        }

    }

    &--circles#{$this}--total2 &__choice {
        margin: 12px;
        @include between(s, m) {
            &:nth-child(2) {
                margin-top: 60px;
            }
        }
        @include from(m) {
            margin: 20px;
            &:nth-child(2) {
                margin-top: 90px;
            }
        }
    }

    &--circles#{$this}--total3 {

        max-width: 320px;
        margin: 0 auto -40px;

        @include from(m) {
            max-width: 600px;
        }

        @include breakpoint($largeBp) {
            max-width: 780px;
            justify-content: space-between;
        }

        #{$this}__choice {
            margin: 12px;
            @include between(s, m) {
                &:nth-child(1) {
                    margin-top: 50px;
                }
                &:nth-child(3) {
                    transform: translate(30px, -40px);
                }
            }
            @include between(m, l) {
                margin: 20px;
                &:nth-child(1) {
                    margin-top: 60px;
                    margin-right: 80px;
                    margin-bottom: -40px;
                }
                &:nth-child(2) {
                    margin-left: 80px;
                    margin-bottom: -40px;
                }
                &:nth-child(3) {
                    margin-left: 40px;
                }
            }
            @include breakpoint($largeBp) {
                margin: 20px;
                &:nth-child(2) {
                    margin-top: 100px;
                }
            }
        }
    }

    &--circles#{$this}--total4 {

        max-width: 320px;
        margin: 0 auto;

        @include between(s, m) {
            justify-content: space-between;

            #{$this}__choice {
                margin: 20px;

                &:nth-child(1) {
                    margin-top: 100px;
                }

                &:nth-child(4) {
                    transform: translate(0, -80px);
                }
            }
        }

        @include between(m, l) {
            max-width: 520px;
            justify-content: space-between;

            #{$this}__choice {
                margin: 20px;

                &:nth-child(1) {
                    margin-top: 100px;
                }

                &:nth-child(4) {
                    transform: translate(0, -80px);
                }
            }
        }

        @include breakpoint($largeBp) {
            max-width: 1080px;
            flex-wrap: nowrap;
            justify-content: space-between;

            #{$this}__choice {
                margin: 20px;

                &:nth-child(2),
                &:nth-child(4) {
                    margin-top: 120px;
                }
            }
        }

    }

    &--pills {

        @include from(m) {
            max-width: 880px;
        }

        #{$this}__choice {
            width: 85%;
            height: 64px;

            &.is-correct {
                border-color: green;
            }

            &.is-wrong {
                border-color: red;
            }

            @include from(m) {
                width: calc(50% - 24px);
                max-width: 430px;
                height: 80px;
            }
        }

    }
}
