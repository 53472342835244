.page-header {

    &__logo-banenor {
        width: 120px;
        height: 19px;

        @include from(m) {
            width: 155px;
            height: 25px;
        }
    }

    &__logo-banorama {
        width: 130px;
        height: 17px;

        @include from(m) {
            width: 172px;
            height: 22px;
        }
    }

}
