.wysiwyg {

    > * {
        margin-bottom: (32/20) * 1em;
    }

    *:last-child {
        margin-bottom: 0;
    }

    *:first-child {
        margin-top: 0;
    }

    li {
        padding-left: 1.75em;
        position: relative;
        margin-bottom: (32/20) * 1em;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    ul li::before {
        content: "";
        width: 1.2em;
        height: 1.2em;
        top: 0.2em;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Zz48cGF0aCBkPSJNMTkgMTFhMSAxIDAgMDEuMTE3IDEuOTkzTDE5IDEzSDVhMSAxIDAgMDEtLjExNy0xLjk5M0w1IDExaDE0eiIvPjxwYXRoIGQ9Ik0xMy4yOTMgNi4yOTNhMSAxIDAgMDExLjMyLS4wODNsLjA5NC4wODMgNSA1YTEgMSAwIDAxLjA4MyAxLjMybC0uMDgzLjA5NC01IDVhMSAxIDAgMDEtMS40OTctMS4zMmwuMDgzLS4wOTRMMTcuNTg1IDEybC00LjI5Mi00LjI5M2ExIDEgMCAwMS0uMDgzLTEuMzJsLjA4My0uMDk0eiIvPjwvZz48L3N2Zz4=');
        background-size: 100% 100%;
    }

    ol {
        counter-reset: list;
    }

    ol li {
        counter-increment: list;
    }

    ol li::before {
        content: counter(list) ".";
    }

    ol ol li::before {
        content: counter(list, lower-alpha) ".";
    }

    li ul,
    li ol {
        padding-top: (32/20) * 1em;
    }

    strong, b {
        font-weight: bold;
    }

    i, em {
        font-style: italic;
    }

    h2, h3, h4 {
        margin: 0 0 0.5em;
        &, strong, b, em, i {
            font-weight: 600;
            font-style: normal;
        }
    }

    a:not([class*="link"]) {

        text-decoration: underline;

        &:active {
            text-decoration-color: transparent;
        }

        @media (hover: hover) {
            transition: text-decoration-color 0.3s $easeOutCubic;
            &:hover {
                text-decoration-color: transparent;
            }
        }

    }

}
