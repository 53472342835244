@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.video-btn-icon {

    &__cta {
        will-change: transform;
        animation: spin 10s infinite linear;
    }

    &__play {
        width: 73.5%;
        height: 73.5%;
    }

    &:active &__play-active {
        visibility: visible;
    }

    @media (hover: hover) {
        &:hover &__play-hover {
            visibility: visible;
        }
    }
}
