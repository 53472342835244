$largeBp: $medium (orientation landscape), $large;

.start-panel {

    &__videobtn {

        width: percentage(83 / 280);
        left: percentage(177 / 280);
        max-width: 180px;
        top: 86px;

        @include from(m) {
            width: 19.5312%;
            left: 71.6145%;
            top: 165px;
        }

        @include breakpoint($largeBp) {
            width: 11.1940%;
            left: 82.8358%;
        }
    }

    &__garbagecan {

        display: none;
        width: 9.9253%;
        left: 27%;
        top: 0;

        @include breakpoint($largeBp) {
            display: block;
        }

        > * {
            transform: translate(0, -33%);
        }

        &-garbage1 {
            width: 23.6363%;
            left: 20%;
            bottom: 15%;
        }

        &-garbage2 {
            width: 17.2727%;
            left: 45%;
            bottom: 13%;
        }
    }

    &__clock {

        width: 15.5%;
        left: 45.5729%;
        top: 0;

        > * {
            transform: translate(0, -10%) scale(-1, 1);
        }

        @include breakpoint($largeBp) {
            width: 10.9402%;
            left: 44.0298%;
            max-width: 200px;
            top: 0;
            > * {
                transform: translate(0, -10%);
            }
        }
    }

    &__logo {

        &-stacked {
            width: 90%;
            //max-width: 768px;
            margin-top: -6%;
            @include from(s) {
                width: 80vmin;
            }
            @include from(m) {
                width: 70vmin;
            }
        }

        &-horizontal {
            display: none;
            margin-top: -6%;
            width: 85%;
            @include breakpoint($largeBp) {
                width: 70%;
            }
        }

        @media (#{'min-aspect-ratio: 1080/720'}) {
            &-stacked {
                display: none;
            }
            &-horizontal {
                display: block;
            }
        }

    }

    &__ppl {

        bottom: 60px;

        &-gumgirl {
            display: none;
        }

        &-cat {
            display: none;
        }

        &-girl {
            width: percentage(110 / 288);
            left: percentage(54 / 288);
        }

        &-man {
            width: percentage(99 / 288);
            left: percentage(146 / 288);
            &-hat {
                width: percentage(54 / 167);
                left: percentage(29 / 167);
                top: 0;
                transition: transform 0.3s $easeOutBack !important;
                transform: scale(1, 0);
                transform-origin: right bottom;
                &.lazyloaded {
                    transform: none;
                }
            }
        }

        &-squirrel {
            display: none;
        }

        @include from(m) {
            bottom: 120px;

            &-gumgirl {
                display: none;
            }

            &-cat {
                display: block;
                width: percentage(192 / 768);
                left: percentage(97 / 768);
            }

            &-girl {
                width: percentage(186 / 768);
                left: percentage(310 / 768);
            }

            &-man {
                width: percentage(168 / 768);
                left: percentage(496 / 768);
            }

            &-squirrel {
                display: none;
            }
        }

        @include breakpoint($largeBp) {

            &-gumgirl {
                display: block;
                left: percentage(83 / 1340);
                width: percentage(180 / 1340);
            }

            &-cat {
                display: block;
                width: percentage(192 / 1340);
                left: percentage(310 / 1340);
            }

            &-girl {
                width: percentage(186 / 1340);
                left: percentage(510 / 1340);
            }

            &-man {
                width: percentage(168 / 1340);
                left: percentage(699 / 1340);
            }

            &-squirrel {
                display: block;
                width: percentage(422 / 1340);
                left: percentage(921 / 1340);
            }
        }
    }

    &__sign {
        width: percentage(169 / 320);
        top: 85px;
        left: 0;
        transform: translate(-(percentage(46 / 169)), 0);
        max-width: 380px;

        @include from(m) {
            width: percentage(300 / 834);
            top: 160px;
        }
        @include breakpoint($largeBp) {
            width: percentage(300 / 1440);
            top: 180px;
        }

        &-plate1 {
            width: percentage(148 / 289);
            left: percentage(140 / 289);
            top: percentage(40 / 203);
        }

        &-plate2 {
            width: percentage(68 / 289);
            left: percentage(182 / 289);
            top: percentage(3 / 203);
        }
    }
}
