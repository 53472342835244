.distraction-panel {
    margin-top: -4px;
    &__anim {
        @include until(m) {
            transform: none !important;
            margin-bottom: 15vmin;
        }
        @include from(m) {
            width: 120%;
            margin-right: -10%;
        }
    }
}
