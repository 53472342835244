.messages {
    &:not([data-component-booted]) {
        display: none !important;
    }
    &__msg {
        &-textwrap {
            min-width: 60px;
            max-width: 252px;
            min-height: 40px;
            border-radius: 20px 20px 0px 20px;
            box-shadow: 0px 0px 16px rgba(#E7F0FD, 0.5);
            strong, b {
                font-weight: 600;
            }
            em, i {
                font-style: italic;
            }
        }
        &-text {
            max-width: inherit;
        }
        &-avatar {
            width: 40px;
            height: 40px;
        }
        &-notification {
            width: 16px;
            height: 16px;
        }
        &-dots {
            width: 20px;
            height: 13px;
        }

        br {
            display: none;
        }
    }
}
