.loader {
    &__inner {
        max-width: 75%;
        @include from(m) {
            max-width: 85%;
        }
    }
    &__bar {
        height: 20px;
        max-width: 420px;
        border-radius: 96px;
        &-progress {
            border-radius: 48px;
        }
        &-head {
            margin-top: 5px;
            svg {
                width: 60px;
                height: 60px;
            }
        }
    }
}
