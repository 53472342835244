.crossing-panel {

    &__track {

        img[data-bg],
        img[data-tunnel] {
            transition: none !important;
        }

        &-anim {
            max-width: 720px;
        }

        &-hotspot {
            width: 0;
            height: 0;
            left: 50%;
            &--1 {
                top: percentage(280 / 1451);
                @include from(m) {
                    margin-left: -(percentage(42 / 372));
                    top: percentage(320 / 1822);
                }
            }

            &--2 {
                top: percentage(460 / 1451);
                @include from(m) {
                    margin-left: percentage(38 / 372);
                    top: percentage(555 / 1822);
                }
            }

            &--3 {
                top: percentage(625 / 1451);
                @include from(m) {
                    margin-left: -(percentage(42 / 372));
                    top: percentage(800 / 1822);
                }
            }

            &--4 {
                top: percentage(890 / 1451);
                @include from(m) {
                    margin-left: percentage(38 / 372);
                    top: percentage(1120 / 1822);
                }
            }

            &--5 {
                top: percentage(1090 / 1451);
                @include from(m) {
                    margin-left: percentage(38 / 372);
                    top: percentage(1380 / 1822);
                }
            }
        }
    }

    &__mountains {
        width: calc(100% + 6px);
        margin-left: -3px;
        margin-top: -(percentage(850 / 1570));
        @include from(m) {
            margin-top: -(percentage(720 / 1570));
        }
        @include from(l) {
            margin-top: -(percentage(550 / 1570));
        }
        &-gfx {
            min-width: 1080px;
            &-front {
                > *::after {
                    content: "";
                    display: block;
                    width: 200vw;
                    min-width: 100%;
                    height: 100vh;
                    background-color: $colorDarkBlue;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    top: calc(100% - 10px);
                    z-index: 1;
                }
            }
        }
    }

    &__slogan {
        &-wrap {
            width: 77.2413%;
        }
        // Elements
        &-handbag {
            width: percentage(107 / 1120);
            left: -(percentage(95 / 1120));
            top: percentage(740 / 990);
        }
        &-headset {
            width: percentage(172 / 1120);
            left: -(percentage(90 / 1120));
            top: percentage(180 / 990);
        }
        &-heart1 {
            width: percentage(135 / 1120);
            left: percentage(840 / 1120);
            top: -(percentage(90 / 990));
        }
        &-heart2 {
            width: percentage(130 / 1120);
            left: percentage(1010 / 1120);
            top: percentage(430 / 990);
        }
        &-heart3 {
            width: percentage(102 / 1120);
            left: -(percentage(20 / 1120));
            top: percentage(570 / 990);
        }
        &-light-red {
            width: percentage(132 / 1120);
            left: percentage(733 / 1120);
            top: percentage(530 / 990);
        }
        &-light-yellow {
            width: percentage(126 / 1120);
            left: percentage(195 / 1120);
            top: -(percentage(250 / 990));
        }
        &-phone {
            width: percentage(132 / 1120);
            left: percentage(808 / 1120);
            top: percentage(258 / 990);
        }
        &-shades {
            width: percentage(200 / 1120);
            left: percentage(920 / 1120);
            top: percentage(608 / 990);
        }
        &-shoe1 {
            width: percentage(201 / 1120);
            left: percentage(450 / 1120);
            top: -(percentage(155 / 990));
        }
        &-shoe2 {
            width: percentage(234 / 1120);
            left: percentage(185 / 1120);
            top: percentage(922 / 990);
        }
        &-thumb1 {
            width: percentage(102 / 1120);
            left: percentage(310 / 1120);
            top: percentage(185 / 990);
        }
        &-thumb2 {
            width: percentage(168 / 1120);
            left: percentage(452 / 1120);
            top: percentage(854 / 990);
        }
        &-watch1 {
            width: percentage(116 / 1120);
            left: percentage(146 / 1120);
            top: percentage(447 / 990);
        }
        &-watch2 {
            width: percentage(180 / 1120);
            left: percentage(1002 / 1120);
            top: percentage(798 / 990);
        }
    }
}
