.panel-menu {

    &__toggle {
        width: 48px;
        height: 48px;
        left: 16px;
        bottom: 16px;
    }

    &__menu {

        @include until(m) {
            width: calc(100% - 40px);

            &-dot {
                width: 16px;
                height: 16px;
                transition: background-color 0.3s;
                .is-active &,
                .is-complete & {
                    background-color: $colorPurple;
                }
            }

            &-label {
                width: 90px;
                margin-top: -2px;
            }

            &-line {
                height: 24px;
            }
            @include scrollable;
        }

        @include from(m) {

            &-wrap {
                border-radius: 40px;
                background: linear-gradient(180deg, #1E285A 0%, #131B43 100%);
            }

            //&-dot {
            //    width: calc(100% + 16px);
            //    height: calc(100% + 16px);
            //    border-radius: 30px;
            //}

        }
    }

    &__dot {
        height: 58px;
        border-radius: 30px;
    }
}
