.quiz-panel {
    &__marquee {
        padding-top: #{'min(40vw, 200px)'};
        @include from(m) {
            padding-top: #{'min(33vw, 400px)'};
        }
    }
    &__entry {
        padding-top: #{'min(30vw, 150px)'};
        @include from(m) {
            padding-top: #{'min(25vw, 250px)'};
        }
    }
    &__plug {
        width: percentage(236 / 288);
        &-circle {
            padding: 20%;
        }
        @include from(m) {
            width: percentage(434 / 768);
            padding-bottom: percentage(104 / 768);
        }
        @include from(l) {
            width: percentage(434 / 1340);
            padding-bottom: percentage(190 / 1340);
        }

        @media (hover: hover) {
            &-ball {
                transition: transform 0.3s;
            }
            &:hover &-ball {
                transform: scale(1.1);
            }
        }
    }
    &__ppl {
        &-man {
            width: percentage(104 / 288);
            margin-left: percentage(15 / 288);

            &-hat {
                width: percentage(54 / 167);
                left: percentage(29 / 167);
                top: 0;
                transition: transform 0.3s $easeOutBack !important;
                transform: scale(1, 0);
                transform-origin: right bottom;
                &.lazyloaded {
                    transform: none;
                }
            }

            @include from(m) {
                width: percentage(136 / 768);
                left: percentage(35 / 768);
                margin: 0;
                bottom: 0;
            }
            @include from(l) {
                width: percentage(227 / 1340);
                left: percentage(210 / 1340);
            }
        }
        &-gumgirl {
            width: percentage(130 / 288);
            margin-right: percentage(15 / 288);
            @include from(m) {
                width: percentage(170 / 768);
                left: percentage(598 / 768);
                margin: 0;
                bottom: 0;
            }
            @include from(l) {
                width: percentage(265 / 1340);
                left: percentage(967 / 1340);
            }
        }
    }
}
