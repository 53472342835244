
.quiz-start {
    &__entry {

        width: percentage(180 / 290);
        max-width: 434px;

        @media (hover: hover) {
            margin-bottom: 5%;
            &-ball {
                transition: transform 0.3s;
            }
            &:hover &-ball {
                transform: scale(1.1);
            }
        }

        @include until(m) {
            &:first-child {
                margin-right: auto;
            }
            &:last-child {
                margin-left: auto;
            }
        }
        @include between(m, l) {
            width: 45%;
            &:first-child {
                transform: translate(-50%, 0);
            }
            &:last-child {
                transform: translate(50%, 0);
                margin-top: -25%;
            }
        }
        @include from(l) {
            width: 50%;
            margin: 0 20px;
            &:last-child {
                margin-top: 5%;
            }
        }
    }

    &__squirrel {
        width: percentage(231 / 290);
        max-width: 250px;
        @include from(m) {
            width: percentage(309 / 768);
            min-width: 309px;
            margin-top: -10%;
        }
        @include from(l) {
            width: percentage(422 / 1440);
            max-width: 422px;
            margin-top: -7%;
        }
    }
}
