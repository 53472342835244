.quiz-nav {
    &__menu {
        width: 85%;
        @include until(m) {
            @include scrollable;
        }
        @include from(m) {
            width: auto;
            display: flex !important;
            transform: none !important;
        }
    }
    &__toggle {
        $this: &;
        &-icon {
            width: 32px;
            height: 32px;
        }
        &[aria-expanded="true"] {
            #{$this}-label {
                display: inline-block !important;
            }
            #{$this}-icon-open {
                opacity: 0;
            }
            #{$this}-icon-close {
                opacity: 1;
            }
        }
    }
}
